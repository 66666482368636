<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Enrollment List By Bu Br Report</h4>
            <div>
              <button
                class="ms-3 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center" v-if="!loading">
              <div class="me-2">
                <label class="col-form-label me-1">From Date</label>
                <div style="width: 250px">
                  <flat-pickr
                    v-model="from_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    @change="getEnrollBuBr(from_date, to_date)"
                  ></flat-pickr>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-2 me-1">To Date</label>
                <div style="width: 250px">
                  <flat-pickr
                    v-model="to_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    @change="getEnrollBuBr(from_date, to_date)"
                  ></flat-pickr>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="dataTable"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th style="width: 150px">No.</th>
                    <th style="width: 200px">BU Name</th>
                    <th style="width: 200px">BU Count</th>
                  </tr>
                </thead>
                <tbody v-if="!loading"></tbody>
                <tbody>
                  <tr v-for="(item, index) in enrollCountList">
                    <td>{{ ++index }}</td>
                    <td>{{ item.business_unit }}</td>
                    <td>{{ item.buCount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import flatPickr from "vue-flatpickr-component";
import { useToast } from "vue-toastification";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import "flatpickr/dist/flatpickr.css";
import $ from "jquery";
import axios from "axios";
import "vue-select/dist/vue-select.css";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    flatPickr,
  },
  data() {
    return {
      from_date: "",
      to_date: "",
      enrollCountList: [],
      employeeLists: [],
      excelLoading: false,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getEnrollBuBr(from_date, to_date) {
      if (!from_date || !to_date) return;
      this.loading = true;
      this.$Progress.start();
      const liveUrl = "https://tis-api.umgclouds.com/api/";
      await axios
        .get(`${liveUrl}enroll-list-byBuBr/${from_date}/${to_date}`)
        .then((response) => {
          this.enrollCountList = response.data.data;

          let buCountList = [];
          let businessUnitCounts = [];

          this.employeeLists.forEach((employee) => {
            this.enrollCountList.forEach((enroll) => {
              if (enroll.enrolled_user_id == employee.emp_id) {
                const businessId = employee.business_unit[0].id;
                const businessName = employee.business_unit[0].name;

                if (businessUnitCounts.hasOwnProperty(businessId)) {
                  businessUnitCounts[businessId].buCount++;
                } else {
                  businessUnitCounts[businessId] = {
                    business_unit: businessName,
                    buCount: 1,
                  };
                }
              }
            });
          });

          buCountList = Object.values(businessUnitCounts);
          this.enrollCountList = buCountList;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error, "error value is ");
          this.toast.error("Something Went Wrong!");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    exportExcelClick() {
      if (this.enrollCountList.length == 0)
        return this.toast.error("No data to export!");
      this.excelLoading = true;
      const headers = ["No", "Business Unit", "Bu Count"];

      const sheetData = [];

      sheetData.push(headers);

      let i = 0;
      this.enrollCountList.forEach((item) => {
        sheetData.push([++i, item.business_unit, item.buCount]);
      });
      exportExcel(sheetData, "Enroll Usage Count");
      this.excelLoading = false;
    },
    refreshData() {
      this.from_date = "";
      this.to_date = "";
      this.enrollCountList = [];
    },
  },
  async created() {
    this.loading = true;
    const liveOdooEndpoint = "https://corehrm.umgclouds.com/api/employees";
    await axios
      .get(liveOdooEndpoint)
      .then((res) => {
        this.employeeLists = res.data.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err, "error is");
        this.toast.err("Something went wrong");
        this.loading = false;
      });
  },
};
</script>
